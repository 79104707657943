import { graphql } from 'gatsby';
import { EventLayout } from '../../components/entities/event-layout';
import { createGatsbyPageComponent } from '@shapeable/ui';

export default createGatsbyPageComponent('Event', { layout: EventLayout });

export const QUERY = graphql`
  query EventQuery($id: ID!) {
    platform {
      event(id: $id) {
        id slug path
        openGraph { title description { plain } image { thumbnails { medium { url } } } }
        name
        name_fr
        name_de
        startDate
        endDate
        description { text }
        description_fr { text }
        description_de { text }
        color { id value darkValue }
        images { image { id url url2x width height } }
        banner {
          id name slug title description { text } alternateText
          image { 
            id url url2x width height 
          }
        }
        links { id url url_fr url_de name label label_fr label_de isDownload page { path } }
        sessions { 
          id name name_fr name_de
          startDate endDate
          icsPath(base: "/downloads")
          description { text }
          description_fr { text }
          description_de { text }
          room { id name shortName name_fr shortName_fr name_de shortName_de }
          color { id value darkValue }
          presenters { 
            id name 
            bio { html }
            bio_fr { html }
            bio_de { html }
            photo { id url }
            openGraph { title description { plain } id image { id thumbnails { id full { id url url2x } } } }
          }
          group { id name name_fr name_de shortName shortName_fr shortName_de }
        }
      }
    }
  }
`;
